import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const SwiperIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '.9rem',
  border: `1px solid`,
}));

const SwiperCardsArrows = ({ next, previous, goToSlide, ...rest }) => {
  let params = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const applicationId = params?.ApplicationID;
  const vacancyId = params.VacancyID;
  const {
    carouselState: { currentSlide, totalItems },
    applications,
  } = rest;
  const { t } = useTranslation('common');

  useEffect(() => {
    const onKeyDown = (e) => {
      if (
        document.activeElement.tagName.toUpperCase() === 'INPUT' ||
        e.target.tagName.toUpperCase() === 'INPUT'
      )
        return;
      if (e?.key === 'ArrowRight') {
        if (applicationId) {
          const nextAppId = applications[currentSlide]?.ApplicationID;
          if (nextAppId) {
            navigate({
              pathname: `/vacancies/${vacancyId}/applications/cards/${nextAppId}/details`,
              search: location.search,
            });
          }
        }
        if (applications.length !== currentSlide) {
          next();
        }
      } else if (e?.key === 'ArrowLeft') {
        if (applicationId) {
          const prevAppId = applications[currentSlide - 2]?.ApplicationID;
          if (prevAppId) {
            navigate({
              pathname: `/vacancies/${vacancyId}/applications/cards/${prevAppId}/details`,
              search: location.search,
            });
          }
        }
        if (currentSlide >= 2) {
          previous();
        }
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [applicationId, applications, next, previous, currentSlide, vacancyId, navigate, location.search]);

  return (
    <Box sx={{}}>
      {currentSlide > 1 && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '0',
          }}
        >
          <Stack direction="column" justifyContent="center" alignItems="flex-start">
            <Box>
              <SwiperIconButton
                className="ev_swipe-prev-button"
                sx={(theme) => ({
                  borderRadius: '50%',
                  border: `1px solid ${theme.vars.palette.primary.main}`,
                })}
                onClick={() => {
                  if (applicationId) {
                    const prevAppId = applications[currentSlide - 2]?.ApplicationID;
                    if (prevAppId) {
                      navigate({
                        pathname: `/vacancies/${vacancyId}/applications/cards/${prevAppId}/details`,
                        search: location.search,
                      });
                    }
                  }
                  previous();
                }}
              >
                <ArrowBackIosRoundedIcon sx={(theme) => ({
                  color: theme.vars.palette.primary.main,
                  fontSize: '1.15rem'
                })} />
              </SwiperIconButton>
            </Box>
            <Typography mt={2} variant="h6" sx={{ fontSize: '.8rem' }}>
              {t('vacancies.swiper.prev')}
            </Typography>
          </Stack>
        </Box>
      )}
      {currentSlide !== totalItems - 1 && (
        <Box
          sx={{
            textAlign: 'right',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',

            right: '0',
          }}
        >
          <Stack direction="column" justifyContent="center" alignItems="flex-end">
            <Box>
              <SwiperIconButton
                className="ev_swipe-next-button"
                sx={(theme) => ({
                  borderRadius: '50%',
                  border: `1px solid ${theme.vars.palette.primary.main}`,
                })}
                onClick={() => {
                  if (applicationId) {
                    const nextAppId = applications[currentSlide]?.ApplicationID;
                    if (nextAppId) {
                      navigate({
                        pathname: `/vacancies/${vacancyId}/applications/cards/${nextAppId}/details`,
                        search: location.search,
                      });
                    }
                  }
                  next();
                }}
              >
                <ArrowForwardIosRoundedIcon sx={(theme) => ({
                  color: theme.vars.palette.primary.main,
                  fontSize: '1.15rem'
                })} />
              </SwiperIconButton>
            </Box>
            <Typography mt={2} variant="h6" sx={{ fontSize: '.8rem' }}>
              {t('vacancies.swiper.next')}
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default SwiperCardsArrows;
