import { Avatar, Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { EmailIcon } from '../components/Icons';

const ProgressCard = ({ member }) => {
  const total = member.Total;
  const reviewed = member.Count;
  const percentageReviewed = (reviewed / total) * 100;
  let counterColor = '#0093b4';
  switch (true) {
    case percentageReviewed >= 100:
      counterColor = '#2e7d32';
      break;
    case percentageReviewed >= 50:
      counterColor = '#ed6c02';
      break;
    default:
      counterColor = '#d32f2f';
      break;
  }

  return (
    <Grid item xs={12} md={6} lg={4} key={member.PersonID}>
      <Card sx={{ borderRadius: '1rem', boxShadow: '0px 8px 8px rgba(0,0,0,0.1)' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardContent sx={{ flex: '1 1 100%' }}>
            {member.PersonImage && (
              <Avatar
                alt={member.PersonName}
                src={`data:image/jpeg;base64,${member.PersonImage}`}
                sx={{ width: 90, height: 90, boxShadow: '0px 8px 8px rgba(0,0,0,0.1)' }}
              />
            )}
            {!member.PersonImage && (
              <Avatar alt={member.PersonName} sx={{ width: 90, height: 90, boxShadow: '0px 8px 8px rgba(0,0,0,0.1)' }}>
                {member.PersonName.charAt(0)}
              </Avatar>
            )}
            <Box mt={3}>
              <Typography variant="h2" sx={{ minHeight: 75 }}>
                {member.PersonName ?? ''}
              </Typography>
            </Box>
          </CardContent>
          <CardContent sx={{ flex: '1 1 120px' }}>
            <Box
              sx={(theme) => ({
                borderRadius: '100%',
                p: 2,
                width: 120,
                height: 120,
                textAlign: 'center',
                bgcolor: counterColor,
                position: 'relative',
                color: theme.vars.palette.primary.contrastText,
                boxShadow: '0px 8px 8px rgba(0,0,0,0.1)'
              })}
            >
              <Typography
                variant="body1"
                color="inherit"
                sx={{
                  fontSize: reviewed < 100 ? '2.25rem' : '1.75rem',
                  fontWeight: 700,
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {reviewed}
                <Box component="span" sx={{ fontWeight: 400 }}>
                  /{member.Total}
                </Box>
              </Typography>
            </Box>
          </CardContent>
        </Box>
        {member.PersonEmail && (
          <CardContent mt={3} sx={{ flex: '1 1 100%' }}>
            <Link className="ano-link" to={`mailto:${member.PersonEmail}`} target="_blank">
              <Stack direction="row" spacing={1} alignItems="center">
                <EmailIcon viewBox="0 0 30 25" width="30" height="30" />
                <Typography variant="caption">{member.PersonEmail}</Typography>
              </Stack>
            </Link>
          </CardContent>
        )}
      </Card>
    </Grid>
  );
};

export default ProgressCard;
