import { Backdrop, Box, Fab, List, ListItem, Popover, Rating, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '../Icons';
import styled from '@emotion/styled';

const BackdropBox = styled(Backdrop)({
  backgroundColor: 'transparent',
});

const PopupCompetenceRating = ({ label, rating }) => {
  return (
    <ListItem
      sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, justifyContent: 'space-between' }}
      alignItems="center"
    >
      <Typography
        variant="body1"
        sx={{
          marginRight: '1rem',
        }}
      >
        {label}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'inline-block',
          bgcolor: theme.vars.palette.grey.A100,
          borderRadius: '2em',
          padding: '.25em 1em .25em 1em',
        })}
      >
        <Rating max={5} readOnly value={rating} sx={{ fontSize: '1.2rem', display: 'flex' }} />
      </Box>
    </ListItem>
  );
};

const CompetenceRatings = ({ applicationData, member }) => {
  const { t } = useTranslation('common');

  const [competencePopover, setCompetencePopover] = useState(null);
  const handleClick = (event) => {
    setCompetencePopover(event.currentTarget);
  };
  const handleClose = () => {
    setCompetencePopover(null);
  };
  const open = Boolean(competencePopover);

  if (
    !member.Competence1 &&
    !member.Competence2 &&
    !member.Competence3 &&
    !member.Competence4 &&
    !member.Competence5
  )
    return <></>;

  return (
    <>
      <Tooltip arrow title={t('cards.card.competencesreview')}>
        <Fab
          color="#EC7A08"
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.warning.main,
            width: '50px',
            height: '50px',
            boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
            '&:hover': {
              backgroundColor: theme.vars.palette.warning.dark,
            },
          })}
          onClick={handleClick}
        >
          <StarIcon stroke="#FFF" width={20} height={20} />
        </Fab>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={competencePopover}
        onClose={handleClose}
        slots={{ backdrop: BackdropBox }}
        disableAutoFocus={true}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={(theme) => ({
          '& .MuiPopover-paper': {
            backgroundColor: '#FFF',
            borderRadius: '1rem',
            boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
          },
        })}
      >
        <Box px={4} pt={3} pb={2}>
          <List
            pt={1}
            sx={{
              padding: 0,
            }}
          >
            {applicationData.Competence1 && member.Competence1 && (
              <PopupCompetenceRating label={applicationData.Competence1} rating={member.Competence1} />
            )}
            {applicationData.Competence2 && member.Competence2 && (
              <PopupCompetenceRating label={applicationData.Competence2} rating={member.Competence2} />
            )}
            {applicationData.Competence3 && member.Competence3 && (
              <PopupCompetenceRating label={applicationData.Competence3} rating={member.Competence3} />
            )}
            {applicationData.Competence4 && member.Competence4 && (
              <PopupCompetenceRating label={applicationData.Competence4} rating={member.Competence4} />
            )}
            {applicationData.Competence5 && member.Competence5 && (
              <PopupCompetenceRating label={applicationData.Competence5} rating={member.Competence5} />
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default CompetenceRatings;
