import styled from '@emotion/styled';
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Popover,
  Typography,
  Avatar,
  Stack,
  Box,
  Divider,
  List,
  Fab,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAfasData from '../hooks/useAfasData';
import ApplicationIcon from '../images/Applications_Icon.svg';
import dayjs from 'dayjs';
import CardListItem from '../components/dialog/CardListItem';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { XIcon } from '../components/Icons';

const BackdropBox = styled(Backdrop)({
  backgroundColor: 'rgba(255,255,255,0)',
});

const VacancyInformation = () => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  let params = useParams();
  const { t, i18n } = useTranslation('common');
  let vacancyId = params.VacancyID;

  // Get comments
  const { data: vacancy = [], isLoading: vacancyIsLoading } = useAfasData(
    `/vacancies/${vacancyId}/details/${i18n.language}`,
    {}
  );

  useEffect(() => {
    const getBoundingClientRect = () => {
      return document.getElementById(`view-details-${vacancyId}`)?.getBoundingClientRect();
    };
    if (open === true) {
      setAnchorEl({
        getBoundingClientRect,
        nodeType: 1,
      });
    }
  }, [vacancyId, open, vacancyIsLoading]);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  return (
    <>
      {anchorEl && (
        <Popover
          id="popover-basic"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
          slots={{ backdrop: BackdropBox }}
          disableAutoFocus={true}
        >
          <Card
            sx={{
              padding: 0,
              borderRadius: 0,
            }}
          >
            {vacancyIsLoading && (
              <CardContent p={4}>
                <Box mt={4}>
                  <CircularProgress />
                </Box>
              </CardContent>
            )}
            {!vacancyIsLoading && vacancy && (
              <>
                <Box
                  className="fixed-close-button"
                  sx={{
                    position: 'absolute',
                    top: '2em',
                    right: '2em',
                  }}
                >
                  <Fab
                    size="small"
                    color="light"
                    sx={(theme) => ({
                      backgroundColor: '#FFF',
                    })}
                    onClick={handleClose}
                  >
                    <XIcon width="18" height="18" />
                  </Fab>
                </Box>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="space-between"
                  alignItems="stretch"
                  spacing={4}
                  sx={(theme) => ({
                    padding: '4rem 4rem 2.5rem 4rem',
                  })}
                >
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                    <Avatar
                      alt="Vacancies to evaluatie"
                      src={ApplicationIcon}
                      sx={(theme) => ({
                        p: '1em',
                        width: 88,
                        height: 88,
                        backgroundColor: theme.vars.palette.primary.main,
                        display: {
                          xs: 'none',
                          sm: 'flex',
                        },
                        boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
                        '& .MuiAvatar-img': {
                          objectFit: 'contain',
                          width: 40,
                          height: 33,
                        },
                      })}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={(theme) => ({
                        display: {
                          xs: 'none',
                          sm: 'block',
                        },
                      })}
                    />
                    <Box>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          variant="h2"
                          component="h1"
                          sx={{
                            flex: 1,
                            maxWidth: '50ch',
                          }}
                        >
                          {vacancy.VacancyTitle}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
                <CardContent
                  sx={{
                    padding: '3rem 4rem 0rem 4rem',
                  }}
                >
                  {vacancy.FunctionWeb && (
                    <Typography
                      my={3}
                      variant="body1"
                      sx={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                      }}
                    >
                      {vacancy.FunctionWeb}
                    </Typography>
                  )}
                  <List mb={4} mt={2}>
                    <CardListItem
                      label={t('vacancies.list.end')}
                      answer={
                        vacancy?.EndDate
                          ? dayjs(vacancy.EndDate).locale(i18n.language).format('D MMMM YYYY')
                          : t('unknown')
                      }
                    />
                    <CardListItem
                      label={t('vacancies.list.start')}
                      answer={
                        vacancy?.StartDate
                          ? dayjs(vacancy.StartDate).locale(i18n.language).format('D MMMM YYYY')
                          : t('unknown')
                      }
                    />
                    {vacancy.Function && (
                      <CardListItem
                        label={t('vacancies.details.function')}
                        answer={<ReactMarkdown>{vacancy.Function}</ReactMarkdown>}
                      />
                    )}
                    {vacancy.Profile && (
                      <CardListItem
                        label={t('vacancies.details.profile')}
                        answer={<ReactMarkdown>{vacancy.Profile}</ReactMarkdown>}
                      />
                    )}
                    {vacancy.Offer && (
                      <CardListItem
                        label={t('vacancies.details.offer')}
                        answer={<ReactMarkdown>{vacancy.Offer}</ReactMarkdown>}
                      />
                    )}
                    {vacancy.InfoAndApplication && (
                      <CardListItem
                        label={t('vacancies.details.infoandapplication')}
                        answer={<ReactMarkdown>{vacancy.InfoAndApplication}</ReactMarkdown>}
                      />
                    )}
                    {vacancy.AboutDepartment && (
                      <CardListItem
                        label={t('vacancies.details.aboutdepartment')}
                        answer={<ReactMarkdown>{vacancy.AboutDepartment}</ReactMarkdown>}
                      />
                    )}
                  </List>
                </CardContent>
              </>
            )}
          </Card>
        </Popover>
      )}
    </>
  );
};

export default VacancyInformation;
