import { Avatar, Box, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IdentityIcon } from '../Icons';

const DetailDialogHeader = ({ applicationData }) => {
  return (
    <>
      <Box
        sx={{
          padding: '3rem 2rem .5rem 3rem',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={3}>
              <Box>
                <Avatar
                  sx={(theme) => ({
                    width: '4.25em',
                    height: '4.25em',
                    backgroundColor: theme.vars.palette.primary.main,
                  })}
                >
                  <IdentityIcon width="43" height="38" style={{ marginBottom: '4px' }} />
                </Avatar>
              </Box>
              <Box>
                <Divider
                  orientation="vertical"
                  sx={{
                    borderRight: 2,
                  }}
                />
              </Box>
              <Stack direction="column" justifyContent="center">
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    minHeight: '2.5rem',
                  }}
                  variant="h2"
                >
                  {applicationData && <>{applicationData.VacancyTitle}</>}
                </Typography>
                <Typography
                  sx={{
                    lineHeight: '2.5rem',
                    fontSize: '2.25rem',
                  }}
                  variant="h1"
                >
                  {applicationData && applicationData.ApplicantName}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default DetailDialogHeader;
