import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Rating,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckIcon, OnHoldIcon, XIcon } from './Icons';
import ClearIcon from '@mui/icons-material/Clear';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { TokenContext } from '../context/TokenContext';
import { afasPoster } from '../hooks/useAfasDataMutation';

export const ApplicationCardContent = styled(CardContent)(() => ({
  paddingTop: '40px',
  paddingBottom: '20px',
  paddingLeft: '30px',
  paddingRight: '30px',
}));

export const ApplicationCard = styled(Card)(({ theme }) => ({
  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: '0',
  paddingRight: '0',
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 15px 15px rgba(0,0,0,0.1)',
  overflow: 'visible',
}));

const ApplicationCardButton = styled(Button)(({ theme }) => ({
  borderRadius: '.5em',
  fontSize: '1.1rem',
  lineHeight: 2,
  paddingLeft: '2.25rem',
  paddingRight: '2.25rem',
}));

const StatusAvatar = styled(Avatar)(() => ({
  backgroundColor: '#FFF',
  width: '4.5em',
  height: '4.5em',
  padding: '.25em',
}));

const CPFab = styled(Fab)(() => ({
  width: 64,
  height: 64,
  boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
}));

const SwiperCard = ({
  application,
  children,
  role,
  view,
  competences,
  applicationMutator,
  infoMutator,
  carouselIndex,
}) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  let params = useParams();
  const navigate = useNavigate();
  const applicationId = application.ApplicationID;
  const vacancyId = params.VacancyID;

  const action = (snackbarId) => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <ClearIcon />
      </IconButton>
    </>
  );

  const token = useContext(TokenContext);
  async function updateApplicationStatus(reaction) {
    const path = `/vacancies/${vacancyId}/applications/${applicationId}/actions`;
    const body = reaction;
    const arg = { token, body };
    let postData = await afasPoster(path, { arg });
    console.log({ postData });
  }

  const [changingStatus, setChangingStatus] = useState('');

  const changeApplicationStatus = async (status) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'changeApplicationStatus',
      status: status,
    });
    try {
      setChangingStatus(status);
      await applicationMutator(updateApplicationStatus({ action: status }), {
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      });
      enqueueSnackbar(t('cards.card.workflow.' + status), {
        action,
        variant: 'success',
      });
      console.log('Successfully changed application status.');
      setChangingStatus('');
      // Trigger mutators after application status has been changed
      infoMutator();
      applicationMutator();
      navigate(
        {
          pathname: location.pathname,
          search: location.search,
        },
        {
          state: {
            slideIndex: carouselIndex,
          },
        }
      );
    } catch (e) {
      enqueueSnackbar(t('cards.card.workflow.failed'), {
        action,
        variant: 'error',
      });
      console.error('Failed to add the new item.');
    }
  };

  /**
   *  VacancyID: number;
   *  ApplicationID: number;
   *  SubjectID: number;
   *  ApplicantID: string;
   *  ApplicantName: string;
   *  Age: number;
   *  NationalityCode: string | null;
   *  Nationality: string | null;
   *  Gender: string;
   *  StatusCode: ApplicationStatusCode;
   *  StatusDescription: string;
   *  Rating: number;
   */
  return (
    <ApplicationCard
      sx={{
        position: 'relative',
        height: '100%',
        borderRadius: '1.5rem',
      }}
    >
      {children}
      <ApplicationCardContent
        className="top-card-content"
        sx={(theme) => ({
          flex: '1 1 100%',
          backgroundColor: theme.vars.palette.grey.A100,
          borderRadius: '1.5rem 1.5rem 0 0',
        })}
      >
        <Box mb={4.5} mt={1}>
          <Typography
            variant="h2"
            align="center"
            component="p"
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              textTransform: 'uppercase',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {application.ApplicantName}
          </Typography>
        </Box>
        <Box mt={1}>
          <Stack mb={2} direction="row" spacing={2} justifyContent="center">
            <StatusAvatar
              sx={{
                fontSize: view && view === 'overview' ? '1em' : '1.25em',
              }}
            >
              <Stack>
                <CheckIcon width="30" height="30" />
                <Box mt={1} textAlign="center">
                  <Typography
                    variant="overline"
                    align="center"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 700,
                      lineHeight: 1.25,
                      fontSize: '1rem',
                      marginTop: '.125rem',
                      color: '#17B733 !important',
                    }}
                  >
                    {application.SuggestInvite}
                  </Typography>
                </Box>
              </Stack>
            </StatusAvatar>
            <StatusAvatar
              sx={{
                fontSize: view && view === 'overview' ? '1em' : '1.25em',
              }}
            >
              <Stack>
                <OnHoldIcon width="30" height="30" />
                <Box mt={1} textAlign="center">
                  <Typography
                    variant="overline"
                    align="center"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 700,
                      lineHeight: 1.25,
                      fontSize: '1rem',
                      color: '#0060AA !important',
                    }}
                  >
                    {application.SuggestOnHold}
                  </Typography>
                </Box>
              </Stack>
            </StatusAvatar>
            <StatusAvatar
              sx={{
                fontSize: view && view === 'overview' ? '1em' : '1.25em',
              }}
            >
              <Stack>
                <XIcon width="30" height="30" />
                <Box mt={1} textAlign="center">
                  <Typography
                    variant="overline"
                    align="center"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 700,
                      lineHeight: 1.25,
                      fontSize: '1rem',
                      marginTop: '.125rem',
                      color: '#E81515 !important',
                    }}
                  >
                    {application.SuggestReject}
                  </Typography>
                </Box>
              </Stack>
            </StatusAvatar>
          </Stack>
          <Box mt={2} mb={2}>
            <Typography
              sx={{
                textTransform: 'none',
                display: 'block',
                lineHeight: '1.75',
                fontSize: '1rem',
              }}
              variant="caption"
              align="center"
            >
              {t('cards.card.status')}
            </Typography>
          </Box>
          <Box mb={2} mt={6}>
            {competences &&
              competences.length > 0 &&
              competences.map((competence, i) => (
                <Stack key={i} mb={1.5} direction="row" spacing={2} justifyContent="space-between">
                  <Typography
                    sx={(theme) => ({
                      lineHeight: 1.1,
                      fontSize: view && view === 'overview' ? '.9em' : '.95em',
                    })}
                  >
                    {competence}
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: '#FFF',
                        borderRadius: '3em',
                        padding: '.25rem .75rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Rating
                        size="small"
                        name="read-only"
                        max={5}
                        value={application[`Competence${i + 1}Average`] || 0}
                        readOnly
                        sx={{
                          fontSize: view && view === 'overview' ? '1rem' : '1rem',
                        }}
                      />
                    </Box>
                  </Box>
                </Stack>
              ))}
          </Box>
        </Box>
      </ApplicationCardContent>
      <ApplicationCardContent
        className="bottom-card-content"
        sx={(theme) => ({
          backgroundColor: '#FFF',
          borderBottomRightRadius: '1em',
          borderBottomLeftRadius: '1em',
          paddingTop: '1.5em',
          borderTop: '1px solid #C2C2C2',
          paddingBottom: '1.5em',
        })}
      >
        {((role !== 'chair_person' && role !== 'hr' && view === 'swipe') || view === 'overview') && (
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <ApplicationCardButton
              className="swiper-card-moreinfo-btn ev_view-application-details"
              variant="contained"
              size="large"
              onClick={() => {
                navigate({
                  pathname: `${application.ApplicationID}/details/`,
                  search: location.search,
                });
              }}
              sx={(theme) => ({
                backgroundColor: '#FFF',
              })}
            >
              {t('cards.card.moreinfo')}
            </ApplicationCardButton>
          </Box>
        )}
        {role && (role === 'chair_person' || role === 'hr') && view === 'swipe' && (
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
            <CPFab
              sx={(theme) => ({
                backgroundColor: theme.vars.palette.grey.A400,
                border: `1px solid ${theme.vars.palette.grey.A400}`,
                width: 72,
                height: 72,
                '&:hover': {
                  backgroundColor: theme.vars.palette.primary.light,
                  borderColor: theme.vars.palette.primary.light,
                },
              })}
              className="ev_view-application-details"
              onClick={() => {
                navigate({
                  pathname: `${application.ApplicationID}/details/`,
                  search: location.search,
                });
              }}
            >
              <Typography
                color="initial"
                sx={(theme) => ({
                  textAlign: 'center',
                  fontWeight: 700,
                  lineHeight: 1.1,
                  fontSize: '.85rem',
                })}
              >
                {t('cards.card.moreinfo')}
              </Typography>
            </CPFab>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: '#A8A8A8',
              }}
            />
            <Tooltip arrow title={t('cards.card.tobeinvited')}>
              <CPFab
                sx={{
                  backgroundColor: '#17B733',
                  '&:hover': {
                    backgroundColor: '#17B733',
                  },
                }}
                onClick={() => {
                  changeApplicationStatus('invite');
                }}
                className="fab-chair-person invite-action"
              >
                {changingStatus === 'invite' ? (
                  <CircularProgress />
                ) : (
                  <CheckIcon width={30} height={30} stroke="#FFF" />
                )}
              </CPFab>
            </Tooltip>
            <Tooltip arrow title={t('cards.card.onhold')}>
              <CPFab
                sx={{
                  backgroundColor: '#0060AA',
                  '&:hover': {
                    backgroundColor: '#0060AA',
                  },
                }}
                onClick={() => {
                  changeApplicationStatus('onhold');
                }}
                className="fab-chair-person hold-action"
              >
                {changingStatus === 'onhold' ? (
                  <CircularProgress />
                ) : (
                  <OnHoldIcon width={30} height={30} fill="#FFF" />
                )}
              </CPFab>
            </Tooltip>
            <Tooltip arrow title={t('cards.card.rejected')}>
              <CPFab
                sx={{
                  backgroundColor: '#E81515',
                  '&:hover': {
                    backgroundColor: '#E81515',
                  },
                }}
                onClick={() => {
                  changeApplicationStatus('reject');
                }}
                className="fab-chair-person reject-action"
              >
                {changingStatus === 'reject' ? (
                  <CircularProgress />
                ) : (
                  <XIcon width={30} height={30} stroke="#FFF" />
                )}
              </CPFab>
            </Tooltip>
          </Stack>
        )}
      </ApplicationCardContent>
    </ApplicationCard>
  );
};

export default SwiperCard;
