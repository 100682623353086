import React, { Suspense, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Await,
  Link,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  useLoaderData,
  useLocation,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  LinearProgress,
  Typography,
  experimental_extendTheme as extendTheme,
} from '@mui/material';
import Progress from './containers/Progress';
import axios from 'axios';
import VacanciesList from './containers/VacanciesList';
import { TokenContext } from './context/TokenContext';
import VacancyRoot from './containers/VacancyRoot';
import VacancyCards from './containers/VacancyCards';
import VacancyDetailDialog from './containers/VacancyDetailDialog';
import VacancyOverview from './containers/VacancyOverview';
import { SnackbarProvider } from 'notistack';
import jsonp from 'jsonp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import VacancyInformation from './containers/VacancyInformation';
import CandidatesList from './containers/CandidatesList';
import { InSiteContext } from './context/InSiteContext';

ReactGA.initialize('G-LGN2Y09EJS');

async function getGladiorToken(tokenurl, code, sessionid, publickey) {
  try {
    // const otp = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/otprequest${window.location.search}`);
    // console.log(otp);
    const {
      data: { token },
    } = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/token`, {
      tokenurl,
      code,
      sessionid,
      publickey,
    });
    return token;
  } catch (error) {
    throw new TokenNotValid('Token not valid');
  }
}

async function getIntegrationSettings(dataurl) {
  try {
    return new Promise((resolve, reject) => {
      jsonp(dataurl, {}, function (err, data) {
        if (err) {
          console.error(err.message);
          reject(err);
        } else {
          resolve({
            scriptUrl: data.scriptUrl,
            cssUrl: data.cssUrl,
          });
        }
      });
    });
  } catch (e) {
    console.warn(e);
  }
}

class BaseError extends Error {
  constructor(message) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export class TokenNotValid extends BaseError {}

export class MissingIntegrationPageSearchParams extends BaseError {}

function ErrorBoundary() {
  const { t, i18n } = useTranslation('common');
  let error = useRouteError();
  let params = new URL(document.location).searchParams;
  let name = params.get('referrer');
  const insiteUrl = useContext(InSiteContext);

  if (error instanceof TokenNotValid) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined">
            <CardContent sx={{ minWidth: '300px', maxWidth: '550px' }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {t('errors.afastitle')}
              </Typography>
              <Typography variant="body1">{t('errors.afasdescription')}</Typography>
              <Link
                className="MuiButton"
                target="_parent"
                to={`${insiteUrl}${i18n === 'nl' ? '/nl' : ''}/recruitment`}
              >
                {t('errors.reload')}
              </Link>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  if (error instanceof MissingIntegrationPageSearchParams) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined">
            <CardContent sx={{ minWidth: '300px', maxWidth: '550px' }}>
              <Typography variant="h3">{t('errors.missingquerytitle')}</Typography>
              <Typography variant="body1">{t('errors.missingquerydescription')}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body1">{t('errors.error')}</Typography>
            <Link className="MuiButton" target="_parent" to={`${insiteUrl}${name}`}>
              {t('errors.reload')}
            </Link>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

function LoadingPage() {
  return (
    <>
      <LinearProgress></LinearProgress>
    </>
  );
}

const GladiorWrapper = ({ gladiorToken, integrationSettings, insiteUrl, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (true) {
      searchParams.set('gladiorToken', gladiorToken);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gladiorToken]);

  return (
    <TokenContext.Provider value={gladiorToken}>
      <InSiteContext.Provider value={insiteUrl}>
        <Helmet>
          <link rel="stylesheet" href={integrationSettings.cssUrl} type="text/css" />
          <script src={integrationSettings.scriptUrl} type="text/javascript" />
        </Helmet>
        {children}
      </InSiteContext.Provider>
    </TokenContext.Provider>
  );
};

function Wrapper() {
  const { gladiorToken, integrationSettings, insiteUrl } = useLoaderData();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        <SnackbarProvider maxSnack={2} autoHideDuration={5000}>
          <Suspense fallback={<LoadingPage />}>
            <Await resolve={gladiorToken}>
              {(resolvedToken) => (
                <Await resolve={insiteUrl}>
                  {(resolvedInsiteUrl) => (
                    <Await resolve={integrationSettings}>
                      {(resolvedIntegrationSettings) => {
                        return (
                          <GladiorWrapper
                            gladiorToken={resolvedToken}
                            integrationSettings={resolvedIntegrationSettings}
                            insiteUrl={resolvedInsiteUrl}
                          >
                            <Outlet />
                          </GladiorWrapper>
                        );
                      }}
                    </Await>
                  )}
                </Await>
              )}
            </Await>
          </Suspense>
        </SnackbarProvider>
      </div>
    </>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<Wrapper />}
      errorElement={<ErrorBoundary />}
      loader={async ({ request, params }) => {
        const searchParams = new URL(request.url).searchParams;
        const tokenurl = searchParams.get('tokenurl');
        const code = searchParams.get('code');
        const sessionid = searchParams.get('sessionid');
        const publickey = searchParams.get('publickey');
        const dataurl = searchParams.get('dataurl');
        const gladiorToken = searchParams.get('gladiorToken');

        if (!tokenurl || !code || !sessionid || !publickey) {
          throw new MissingIntegrationPageSearchParams('Missing params');
        }
        const integrationSettings = getIntegrationSettings(dataurl);
        if (!gladiorToken) {
          const gladiorTokenResponse = getGladiorToken(tokenurl, code, sessionid, publickey);
          return defer({
            gladiorToken: gladiorTokenResponse,
            insiteUrl: tokenurl.replace('/integrationtoken', ''),
            integrationSettings,
          });
        } else {
          return defer({
            gladiorToken: gladiorToken,
            insiteUrl: tokenurl.replace('/integrationtoken', ''),
            integrationSettings,
          });
        }
      }}
    >
      <Route path="/" element={<VacanciesList />}>
        <Route path="details/:VacancyID" element={<VacancyInformation />} />
      </Route>
      <Route path="candidates" element={<CandidatesList />}></Route>
      <Route path="vacancies/:VacancyID" element={<VacancyRoot />}>
        <Route path="evaluation-progress" element={<Progress />} />
        <Route path="applications/cards" element={<VacancyCards />}>
          <Route path="details" element={<VacancyInformation />} />
          <Route path=":ApplicationID/details" element={<VacancyDetailDialog />} />
        </Route>
        <Route path="applications/overview" element={<VacancyOverview />}>
          <Route path="details" element={<VacancyInformation />} />
          <Route path=":ApplicationID/details" element={<VacancyDetailDialog />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  // const theme = createTheme(utTheme);
  const theme = extendTheme({
    cssVarPrefix: '',
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            position: 'relative',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'unset',
            '&:hover': {
              boxShadow: 'unset',
            },
          },
        },
      },
    },
  });
  return (
    <>
      <CssVarsProvider theme={theme}>
        <RouterProvider router={router} />
      </CssVarsProvider>
    </>
  );
}

export default App;
