import { Box, Stack } from '@mui/system';
import { useLocation } from 'react-router-dom';
import CardsHeaderButton from '../components/CardsHeaderButton';
import TotalAppliedIcon from '../images/TotalAppliedCandidates.svg';
import CheckIcon from '../images/Check_Icon.svg';
import OnHoldIcon from '../images/OnHoldIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import ReviewedIcon from '../images/ReviewedIcon.svg';
import { useTranslation } from 'react-i18next';
import OverviewHeaderTitle from './OverviewHeaderTitle';

const CardsHeader = ({ vacancyData, vacancyIsLoading }) => {
  const { t } = useTranslation('common');

  const location = useLocation();
  const totalCandidates = location?.state?.data?.candidates || '';

  /**
   * TotalCandidates: 32
   * TotalInvited: 9
   * TotalOnHold: 11
   * TotalRejected: 11
   * TotalReviewOpen: 1
   * VacancyID: 762
   * VacancyTitle: "Assistant Professor: Artificial Intelligence in Biomechanics and Robotics"
   * Competence1: Competence_Rating
   * Competence2: Competence_Rating
   * Competence3: Competence_Rating
   * Competence4: Competence_Rating
   * Competence5: Competence_Rating
   */

  return (
    <>
      <Box mb={4} mt={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="start"
          spacing={4}
        >
          <OverviewHeaderTitle
            subPath="cards"
            vacancyData={vacancyData}
            vacancyIsLoading={vacancyIsLoading}
          />
          <Stack
            direction="row"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            alignItems="flex-start"
            spacing={0}
            sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
          >
            <CardsHeaderButton
              color="#4e1370"
              text={<>{t('cards.header.applied')}</>}
              number={vacancyData.TotalCandidates || totalCandidates}
              icon={TotalAppliedIcon}
              status="all"
              width="32"
              height="32"
            />
            <CardsHeaderButton
              color="#17B733"
              text={<>{t('cards.header.invited')}</>}
              number={vacancyData.TotalInvited || '0'}
              icon={CheckIcon}
              status="003"
              width="32"
              height="32"
            />
            <CardsHeaderButton
              color="#0060AA"
              text={<>{t('cards.header.onhold')}</>}
              number={vacancyData.TotalOnHold || '0'}
              icon={OnHoldIcon}
              status="004,009"
              width="32"
              height="32"
            />
            <CardsHeaderButton
              color="#E81515"
              text={<>{t('cards.header.rejected')}</>}
              number={vacancyData.TotalRejected || '0'}
              icon={RejectedIcon}
              status="005,008"
              width="32"
              height="32"
              classAddOn={'rejected'}
            />
            <CardsHeaderButton
              color="#afafaf"
              text={<>{t('cards.header.reviewed')}</>}
              number={vacancyData.TotalReviewOpen || '0'}
              icon={ReviewedIcon}
              status='002'
              width="32"
              height="32"
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default CardsHeader;
