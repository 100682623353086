import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_nl from './translations/nl/common.json';
import common_en from './translations/en/common.json';
// import * as Sentry from '@sentry/react';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://2cac5a5e76774ec3902e3896f97290c3@o4505073697554432.ingest.sentry.io/4505073699323904',
//     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang') ?? 'nl';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lang, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    nl: {
      common: common_nl,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

registerServiceWorker();
