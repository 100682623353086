import axios from 'axios';
import { useContext } from 'react';
import useSWRMutation from 'swr/mutation';
import { TokenContext } from '../context/TokenContext';

let baseUrl = process.env.REACT_APP_ENDPOINT_URL;

export const afasPoster = async (path, { arg }) => {
  const { token, body } = arg;

  return axios
    .post(`${baseUrl}${path}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

function useAfasDataMutation(path) {
  const token = useContext(TokenContext);
  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating, error, data } = useSWRMutation(path ? path : null, afasPoster, {
    errorRetryCount: 3,
  });

  function doTrigger(body) {
    return trigger({ token: token, body: body });
  }

  return {
    trigger: doTrigger,
    data,
    isMutating,
    error,
  };
}

export default useAfasDataMutation;
