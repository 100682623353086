import { Fab, IconButton, LinearProgress, Modal, Stack, SvgIcon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import CardsHeader from '../components/CardsHeader';
import SwiperCard from '../components/SwiperCard';
import SwiperCardsArrows from '../components/SwiperCardsArrows';
import useAfasData from '../hooks/useAfasData';
import BackToOverview from '../components/BackToOverview';
import { useLocation } from 'react-router-dom';
import { XIcon } from '../components/Icons';

function CardSwipeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 640 512">
      <path fill={props?.fill ? props.fill : '#ADAFBB'} d="M 220.7 7.468 C 247.3 -7.906 281.4 1.218 296.8 27.85 L 463.8 317.1 C 479.1 343.8 470 377.8 443.4 393.2 L 250.5 504.5 C 223.9 519.9 189.9 510.8 174.5 484.2 L 7.468 194.9 C -7.906 168.2 1.218 134.2 27.85 118.8 L 220.7 7.468 Z M 324.1 499 L 459.4 420.9 C 501.3 396.7 515.7 343.1 491.5 301.1 L 354.7 64.25 C 356.5 64.08 358.2 63.1 360 63.1 L 584 63.1 C 614.9 63.1 640 89.07 640 119.1 L 640 456 C 640 486.9 614.9 512 584 512 L 360 512 C 346.4 512 333.8 507.1 324.1 498.1 L 324.1 499 Z" />
    </SvgIcon>
  );
}

function CardOverviewIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 448 512">
      <path fill={props?.fill ? props.fill : '#ADAFBB'} d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C128 46.33 113.7 32 96 32zM256 32H192C174.3 32 160 46.33 160 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C288 46.33 273.7 32 256 32zM416 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C448 46.33 433.7 32 416 32zM96 352H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C128 366.3 113.7 352 96 352zM256 352H192c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C288 366.3 273.7 352 256 352zM416 352h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C448 366.3 433.7 352 416 352zM96 192H32C14.33 192 0 206.3 0 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C128 206.3 113.7 192 96 192zM256 192H192C174.3 192 160 206.3 160 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C288 206.3 273.7 192 256 192zM416 192h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C448 206.3 433.7 192 416 192z" />
    </SvgIcon>
  );
}

function VacancyCards() {
  const params = useParams();
  const navigate = useNavigate();
  const carouselRef = useRef();
  const vacancyId = params.VacancyID;

  const { t } = useTranslation('common');
  const location = useLocation();
  const slideIndex = location?.state?.slideIndex || 1;
  let carouselIndex = 0;

  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const {
    data: applicationData = [],
    isLoading: applicationIsLoading,
    mutate: applicationMutator,
  } = useAfasData(`/vacancy/${vacancyId}/applications/open`, {});

  const {
    data: vacancyData = {},
    isLoading: vacancyIsLoading,
    mutate: infoMutator,
  } = useAfasData(`/vacancies/${vacancyId}/info`, {});

  useEffect(() => {
    carouselRef?.current?.goToSlide(1);
  }, [carouselRef]);

  useEffect(() => {
    if (slideIndex) {
      if (slideIndex === carouselRef?.current?.state?.totalItems) {
        carouselRef?.current?.goToSlide(slideIndex - 1);
      } else {
        carouselRef?.current?.goToSlide(slideIndex);
      }
    }
  }, [carouselRef, applicationData, slideIndex]);

  const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '94%',
    maxWidth: '1100px',
    bgcolor: 'rgba(30, 35, 39, .92)',
    minHeight: '500px',
    display: 'flex',
    alignItems: 'center',
    p: 4,
  };

  const competences = [];
  if (vacancyData) {
    if (vacancyData.Competence1 !== '') {
      competences.push(vacancyData.Competence1);
    }
    if (vacancyData.Competence2 !== '') {
      competences.push(vacancyData.Competence2);
    }
    if (vacancyData.Competence3 !== '') {
      competences.push(vacancyData.Competence3);
    }
    if (vacancyData.Competence4 !== '') {
      competences.push(vacancyData.Competence4);
    }
    if (vacancyData.Competence5 !== '') {
      competences.push(vacancyData.Competence5);
    }
  }

  return (
    <>
      <CardsHeader vacancyData={vacancyData} vacancyIsLoading={vacancyIsLoading} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BackToOverview />
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
          <IconButton
            sx={{
              borderBottom: '3px solid',
              borderRadius: '0px',
            }}
          >
            <CardSwipeIcon width="30" height="34" />
          </IconButton>
          <IconButton
            className="ev_view-vacancy-applications-grid"
            onClick={() => {
              navigate(
                {
                  pathname: `/vacancies/${vacancyId}/applications/overview/`,
                  search: location.search,
                },
                {
                  state: {
                    statusCode: 'all',
                  },
                }
              );
            }}
            sx={{
              borderBottom: '3px solid transparent',
              borderRadius: '0px',
            }}
          >
            <CardOverviewIcon width="30" height="34" />
          </IconButton>
        </Stack>
      </Stack>
      <Box pb={5}>
        {applicationIsLoading && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )}
        {vacancyData && applicationData.length > 0 && !applicationIsLoading && (
          <Carousel
            customButtonGroup={<SwiperCardsArrows applications={applicationData} />}
            ref={carouselRef}
            arrows={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 564, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
            slidesToSlide={1}
            minimumTouchDrag={40}
            transitionDuration={500}
            centerMode={true}
            infinite={false}
            keyBoardControl
            swipeable
          >
            <Box
              key={0}
              sx={{
                visibility: 'hidden',
                pointerEvents: 'none',
              }}
            >
              <SwiperCard
                application={{
                  VacancyID: 0,
                  ApplicationID: 0,
                  SubjectID: 0,
                  ApplicantID: '',
                  ApplicantName: '',
                  Age: 0,
                  NationalityCode: 0,
                  Nationality: 0,
                  Gender: 0,
                  StatusCode: 0,
                  StatusDescription: 0,
                  Rating: 0,
                }}
              />
            </Box>
            {applicationData.map((application) => {
              carouselIndex++;
              return (
                <Box key={application.ApplicationID}>
                  <SwiperCard
                    application={application}
                    role={vacancyData?.Role || undefined}
                    competences={competences}
                    applicationMutator={applicationMutator}
                    infoMutator={infoMutator}
                    view="swipe"
                    carouselIndex={carouselIndex}
                  />
                </Box>
              );
            })}
          </Carousel>
        )}
        {!applicationIsLoading && applicationData.length === 0 && (
          <>
            <Typography variant="h4" align="center" color="initial">
              {t('cards.card.none')}
            </Typography>
            <Typography variant="body1" color="initial" align="center">
              {t('cards.card.tooverview')}
            </Typography>
          </>
        )}
      </Box>
      <Outlet context={{ carouselRef: carouselRef }} />
    </>
  );
}

export default VacancyCards;
