import { Box } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';

const CardsHeaderButton = ({ color, text, number, icon, status, width = 40, height = 40 }) => {
  const params = useParams();
  const vacancyId = params.VacancyID;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box
      className="cards-header-button"
      sx={{
        textAlign: 'center',
        maxWidth: '120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        variant="text"
        className={
          status === '002'
            ? 'active ev_view-applications-grid-preselected-status'
            : 'ev_view-applications-grid-preselected-status'
        }
        onClick={() => {
          navigate(
            {
              pathname: `/vacancies/${vacancyId}/applications/overview/`,
              search: location.search,
            },
            {
              state: {
                statusCode: status,
              },
            }
          );
        }}
        sx={{
          p: 2,
          display: 'block',
          width: 90,
          height: 90,
          bgcolor: '#FFF !important',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: 'unset !important',
          textAlign: 'center !important',
          fontWeight: 700,
          borderRadius: '100% !important',
          boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
          '&:hover': {
            bgcolor: `${color} !important`,
          },
          '&:hover .MuiTypography-h4': {
            color: '#FFF !important',
          },
          '&:hover .MuiAvatar-img': {
            filter: 'brightness(100)',
          },
          '&.active': {
            bgcolor: `${color} !important`,
          },
          '&.active .MuiTypography-h4': {
            color: '#FFF !important',
          },
          '&.active:hover': {
            bgcolor: `${color} !important`,
          },
          '&.active .MuiAvatar-img': {
            filter: 'brightness(100)',
          },
        }}
      >
        <Avatar
          alt="Icon"
          src={icon}
          sx={{
            width: Number(width),
            height: Number(height),
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '0',
            '& .MuiAvatar-img': {
              objectFit: 'contain',
              transition: 'all ease-in-out 250ms',
            },
          }}
        />
        <Typography
          variant="h4"
          color={color}
          mt={1}
          sx={{
            fontSize: '1.15rem',
            transition: 'all ease-in-out 250ms',
            color: `${color} !important`,
          }}
        >
          {number}
        </Typography>
      </Button>
      <Box mt={1}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '.85rem !important',
            minWidth: '120px',
            whiteSpace: 'pre-wrap',
            fontWeight: `500 !important`,
          }}
          color="initial"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardsHeaderButton;
