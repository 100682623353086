import { Box, Button, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from './Icons';

export const BackIconButton = styled(Box)({
  borderRadius: '1.5rem',
  width: '1.5rem',
  height: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all .25s ease-in-out',
});

const BackToOverview = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      className="ev_back-to-overview"
      sx={{
        padding: 0,
        bgcolor: 'transparent',
        '&:hover': {
          bgcolor: 'transparent',
          color: 'inherit',
        },
      }}
      disableFocusRipple={true}
      disableRipple={true}
      onClick={() => {
        navigate({
          pathname: `/`,
          search: location.search,
        });
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} mr={2}>
        <BackIconButton>
          <ArrowBackIcon width=".75rem" height=".75rem" fill="#FFF" />
        </BackIconButton>
        {t('main.backto')}
      </Stack>
    </Button>
  );
};

export default BackToOverview;
