import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Rating,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import relativeTime from 'dayjs/plugin/relativeTime';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { afasPoster } from '../../hooks/useAfasDataMutation';
import { TokenContext } from '../../context/TokenContext';
import { DetailDialogSectionTitle } from '../../containers/VacancyDetailDialog';
import { CheckIcon, OnHoldIcon, RollbackIcon, XIcon } from '../Icons';
import CommentListItem from './CommentListItem';
import _orderBy from 'lodash/orderBy';

export const hasCompetences = (applicationData) => {
  if (
    applicationData.Competence1 ||
    applicationData.Competence2 ||
    applicationData.Competence3 ||
    applicationData.Competence4 ||
    applicationData.Competence5
  )
    return true;
  return false;
};

const CompetenceRatingItem = ({ competenceName, competenceValue, setCompetenceValue }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
      <Typography variant="body1">{competenceName}</Typography>
      <Box
        py={1}
        px={2}
        sx={(theme) => ({
          display: 'inline-block',
          bgcolor: theme.vars.palette.grey.A100,
          borderRadius: '2em',
          padding: '.25em 1em .25em 1em',
          marginBottom: '.5em',
        })}
      >
        <Rating
          value={competenceValue}
          max={5}
          sx={{ fontSize: '1.2rem', display: 'flex' }}
          onChange={(event, newValue) => {
            setCompetenceValue(newValue);
          }}
        />
      </Box>
    </Stack>
  );
};

const StatusFab = (props) => {
  const active = props.activated === 'true' ? true : false;
  const activebgcolor = props.activebgcolor;

  return (
    <ToggleButton
      {...props}
      sx={{
        width: '88px',
        height: '88px',
        marginRight: '2.25rem',
        borderRadius: '50% !important',
        transition: '250ms all ease-in-out',
        boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
        backgroundColor: active ? `${activebgcolor} !important` : `#FFF !important`,
        '&:hover': {
          backgroundColor: active ? `${activebgcolor} !important` : `#CECECE !important`,
        },
      }}
    >
      {props.children}
    </ToggleButton>
  );
};

const StatusSuggestion = ({ role, status, setStatus, changingStatus, originalStatus }) => {
  const { t } = useTranslation('common');
  return (
    <ToggleButtonGroup mt={2} value={status} onChange={setStatus} exclusive>
      <Tooltip
        arrow
        title={['chair_person'].includes(role) ? t('cards.card.tobeinvited') : t('cards.card.inviteadvice')}
      >
        <StatusFab value={3} activated={status === 3 ? 'true' : 'false'} activebgcolor="#17B733">
          {changingStatus === 'invite' ? (
            <CircularProgress />
          ) : (
            <CheckIcon width="32" height="32" stroke={status === 3 ? '#FFF' : '#17B733'} />
          )}
        </StatusFab>
      </Tooltip>
      <Tooltip
        arrow
        title={
          ['chair_person'].includes(role)
            ? originalStatus !== '004'
              ? t('cards.card.onhold')
              : t('cards.card.selection_committee')
            : t('cards.card.onholdadvice')
        }
      >
        <StatusFab
          value={2}
          activated={status === 2 ? 'true' : 'false'}
          activebgcolor={originalStatus !== '004' ? '#0060AA' : '#afafaf'}
        >
          {(originalStatus !== '004' || ['committee_member'].includes(role)) && (
            <>
              {changingStatus === 'onhold' ? (
                <CircularProgress />
              ) : (
                <OnHoldIcon
                  width="32"
                  height="32"
                  stroke={status === 2 ? '#FFF' : '#0060AA'}
                  fill={status === 2 ? '#FFF' : '#0060AA'}
                />
              )}
            </>
          )}
          {originalStatus === '004' && ['chair_person'].includes(role) && (
            <>
              {changingStatus === 'onhold' ? (
                <CircularProgress />
              ) : (
                <RollbackIcon width="32" height="32" fill={status === 2 ? '#FFF' : '#afafaf'} />
              )}
            </>
          )}
        </StatusFab>
      </Tooltip>
      <Tooltip
        arrow
        title={['chair_person'].includes(role) ? t('cards.card.rejected') : t('cards.card.rejectadvice')}
      >
        <StatusFab value={1} activated={status === 1 ? 'true' : 'false'} activebgcolor="#E81515">
          {changingStatus === 'reject' ? (
            <CircularProgress />
          ) : (
            <XIcon width="30" height="30" stroke={status === 1 ? '#FFF' : '#E81515'} />
          )}
        </StatusFab>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

const CardReviewSection = ({
  role,
  commentData,
  commentDataIsLoading,
  mutateReaction,
  listMutator,
  infoMutator,
  openMutator,
  applicationIsLoading,
  applicationData,
  currentIndex,
}) => {
  dayjs.extend(relativeTime);

  const { t } = useTranslation('common');
  let params = useParams();
  const location = useLocation();
  let navigate = useNavigate();

  const vacancyId = params.VacancyID;
  const applicationId = params.ApplicationID;

  const [ratingValue, setRatingValue] = useState(0);
  const [decisionValue, setDecisionValue] = useState(0);
  useEffect(() => {
    if (
      (applicationData.StatusCode === '005' || applicationData.StatusCode === '008') &&
      ['chair_person', 'hr'].includes(role)
    ) {
      setDecisionValue(1);
    }
    if (
      (applicationData.StatusCode === '004' || applicationData.StatusCode === '009') &&
      ['chair_person', 'hr'].includes(role)
    ) {
      setDecisionValue(2);
    }
    if (applicationData.StatusCode === '003' && ['chair_person', 'hr'].includes(role)) {
      setDecisionValue(3);
    }
  }, [applicationData, role]);

  const [competence1Value, setCompetence1Value] = useState(0);
  const [competence2Value, setCompetence2Value] = useState(0);
  const [competence3Value, setCompetence3Value] = useState(0);
  const [competence4Value, setCompetence4Value] = useState(0);
  const [competence5Value, setCompetence5Value] = useState(0);

  const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);

  const [canChangeDecision, setCanChangeDecision] = useState(false);
  useEffect(() => {
    if (['chair_person'].includes(role) && ['004', '002'].includes(applicationData.StatusCode)) {
      setCanChangeDecision(true);
    } else {
      setCanChangeDecision(false);
    }
  }, [
    ratingValue,
    competence1Value,
    competence2Value,
    competence3Value,
    competence4Value,
    competence5Value,
    role,
    applicationData,
  ]);

  useEffect(() => {
    if (role !== 'chair_person' && role !== 'hr' && (!applicationData || !ratingValue)) {
      setButtonDisabledStatus(true);
    } else {
      setButtonDisabledStatus(false);
    }
  }, [ratingValue, applicationData, role]);

  const handleStatus = (event, newStatus) => {
    setRatingValue(newStatus);
  };

  const handleDecision = (event, newStatus) => {
    setDecisionValue(newStatus);
  };

  const [comment, setComment] = useState('');
  const [postComment, setPostComment] = useState(false);

  const token = useContext(TokenContext);

  const action = (snackbarId) => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <ClearIcon />
      </IconButton>
    </>
  );

  const comments = _orderBy(commentData.comments, ['Date'], ['desc']);
  const members = commentData.members;

  useEffect(() => {
    if (!members) return;
    const myMember = Object.values(members).find((member) => member.IsSelf);
    if (myMember?.Rating) {
      setRatingValue(myMember?.Rating);
    }
    if (myMember?.Competence1) {
      setCompetence1Value(myMember?.Competence1);
    }
    if (myMember?.Competence2) {
      setCompetence2Value(myMember?.Competence2);
    }
    if (myMember?.Competence3) {
      setCompetence3Value(myMember?.Competence3);
    }
    if (myMember?.Competence4) {
      setCompetence4Value(myMember?.Competence4);
    }
    if (myMember?.Competence5) {
      setCompetence5Value(myMember?.Competence5);
    }
  }, [members, applicationData, role]);

  async function insertReaction(reaction) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'insertReaction',
      rating: reaction?.rating,
      comment: reaction?.comment,
      competences: JSON.stringify(reaction?.competences),
    });
    // Reaction example
    // rating: Number(ratingValue),
    // comment: comment ?? false,
    // competences: [
    //   competence1Value !== 0 ? competence1Value : null,
    //   competence2Value !== 0 ? competence2Value : null,
    //   competence3Value !== 0 ? competence3Value : null,
    //   competence4Value !== 0 ? competence4Value : null,
    //   competence5Value !== 0 ? competence5Value : null,
    // ],
    const path = `/vacancies/${vacancyId}/applications/${applicationId}/ratings`;
    const body = reaction;
    const arg = { token, body };
    let postData = await afasPoster(path, { arg });
    console.log({ postData });
    const myMember = Object.values(members).find((member) => member.IsSelf);
    let updatedReactions = {
      comments: [
        {
          PersonID: myMember?.PersonID ?? null,
          Date: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
          Comment: comment ?? '',
        },
        ...commentData.comments,
      ],
      members: {
        [myMember?.PersonID]: {
          PersonID: myMember?.PersonID,
          PersonName: myMember?.PersonName,
          PersonImage: myMember?.PersonImage,
          Rating: Number(ratingValue),
          Competence1: competence1Value !== 0 ? competence1Value : null,
          Competence2: competence2Value !== 0 ? competence2Value : null,
          Competence3: competence3Value !== 0 ? competence3Value : null,
          Competence4: competence4Value !== 0 ? competence4Value : null,
          Competence5: competence5Value !== 0 ? competence5Value : null,
        },
        ...commentData.members,
      },
    };
    return updatedReactions;
  }

  async function updateApplicationStatus(reaction) {
    const path = `/vacancies/${vacancyId}/applications/${applicationId}/actions`;
    const body = reaction;
    const arg = { token, body };
    let postData = await afasPoster(path, { arg });
    console.log({ postData });
  }

  const [changingStatus, setChangingStatus] = useState('');
  const [changingDecision, setChangingDecision] = useState('');
  const [savingDecision, setSavingDecision] = useState(false);

  function getBack() {
    let dPath = '';
    if (location.pathname.includes('cards')) {
      dPath = 'cards';
    } else {
      dPath = 'overview';
    }
    navigate(
      {
        pathname: `/vacancies/${vacancyId}/applications/${dPath}`,
        search: location.search,
      },
      { state: { slideIndex: currentIndex } }
    );
  }

  async function handleDecisionSaving() {
    if (!canChangeDecision) return;
    try {
      setSavingDecision(true);
      switch (decisionValue) {
        case 1:
          setChangingDecision('reject');
          changeApplicationStatus('reject');
          break;
        case 2:
          setChangingDecision('onhold');
          changeApplicationStatus(applicationData.StatusCode === '002' ? 'onhold' : 'selection_committee');
          break;
        case 3:
          setChangingDecision('invite');
          changeApplicationStatus('invite');
          break;
        default:
          break;
      }
      setSavingDecision(false);
    } catch (e) {
      enqueueSnackbar(t('cards.card.comment.failed'), {
        action,
        variant: 'error',
      });
      console.error('Failed to add the new item.');
      setPostComment(false);
    }
  }

  const changeApplicationStatus = async (status) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'changeApplicationStatus',
      status: status,
    });
    try {
      await openMutator(updateApplicationStatus({ action: status }), {
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      });
      enqueueSnackbar(t('cards.card.workflow.' + status), {
        action,
        variant: 'success',
      });
      console.log('Successfully changed application status.');
      // Trigger mutators after application status has been changed
      infoMutator();
      openMutator();
      listMutator();
      getBack();
    } catch (e) {
      enqueueSnackbar(t('cards.card.workflow.failed'), {
        action,
        variant: 'error',
      });
      console.error('Failed to add the new item.');
    }
  };

  async function handleCommentAndRatings() {
    setPostComment(true);
    switch (Number(ratingValue)) {
      case 1:
        setChangingStatus('reject');
        break;
      case 2:
        setChangingStatus('onhold');
        break;
      case 3:
        setChangingStatus('invite');
        break;
      default:
        break;
    }
    let newReaction = {
      rating: Number(ratingValue),
      comment: comment ?? false,
      competences: [
        competence1Value !== 0 ? competence1Value : null,
        competence2Value !== 0 ? competence2Value : null,
        competence3Value !== 0 ? competence3Value : null,
        competence4Value !== 0 ? competence4Value : null,
        competence5Value !== 0 ? competence5Value : null,
      ],
    };
    const myMember = Object.values(members).find((member) => member.IsSelf);
    let optimisticData = {
      comments: [
        ...commentData.comments,
        {
          PersonID: myMember?.PersonID ?? null,
          Date: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
          Comment: comment ?? '',
        },
      ],
      members: {
        ...commentData.members,
        [myMember?.PersonID]: {
          PersonID: myMember?.PersonID,
          PersonName: myMember?.PersonName,
          PersonImage: myMember?.PersonImage,
          Rating: Number(ratingValue),
          Competence1: competence1Value !== 0 ? competence1Value : null,
          Competence2: competence2Value !== 0 ? competence2Value : null,
          Competence3: competence3Value !== 0 ? competence3Value : null,
          Competence4: competence4Value !== 0 ? competence4Value : null,
          Competence5: competence5Value !== 0 ? competence5Value : null,
        },
      },
    };

    try {
      await mutateReaction(insertReaction(newReaction), {
        optimisticData: optimisticData,
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      });
      enqueueSnackbar(t('cards.card.comment.saved'), {
        action,
        variant: 'success',
      });
      console.log('Successfully added the new item.');
      setComment('');
      setChangingStatus('');
      setPostComment(false);
      infoMutator();
      openMutator();
      listMutator();
      mutateReaction();
    } catch (e) {
      // If the API errors, the original data will be
      // rolled back by SWR automatically.
      enqueueSnackbar(t('cards.card.comment.failed'), {
        action,
        variant: 'error',
      });
      console.error('Failed to add the new item.');
      setPostComment(false);
    }
  }

  return (
    <>
      {['committee_member', 'chair_person', 'hr'].includes(role) && (
        <Box
          sx={{
            padding: '2rem 3rem 0rem',
          }}
          id="review-applicant"
        >
          <Box
            sx={(theme) => ({
              padding: '2.5em 2em 2.5em 3em',
              bgcolor: theme.vars.palette.background.default,
            })}
          >
            <Stack direction="row" spacing={14}>
              <Box
                sx={(theme) => ({
                  flex: '1 1 45%',
                })}
              >
                {applicationIsLoading && (
                  <>
                    <Skeleton variant="rounded" height={750} />
                  </>
                )}
                {!applicationIsLoading && applicationData && (
                  <>
                    <DetailDialogSectionTitle
                      sx={(theme) => ({ color: `${theme.vars.palette.text.primary} !important` })}
                      variant="h1"
                      className="detail-dialog-section-title"
                    >
                      {`${t('cards.card.comment.title')}${applicationData?.ApplicantName}`}
                    </DetailDialogSectionTitle>
                    {hasCompetences(applicationData) && (
                      <Box mb={8}>
                        <Typography variant="h6" fontSize="1.25rem !important" mb=".5rem !important">
                          {t('cards.card.reviewcompetences')}
                        </Typography>
                        {applicationData.Competence1 && (
                          <CompetenceRatingItem
                            competenceName={applicationData.Competence1}
                            competenceValue={competence1Value}
                            setCompetenceValue={setCompetence1Value}
                          />
                        )}
                        {applicationData.Competence2 && (
                          <CompetenceRatingItem
                            competenceName={applicationData.Competence2}
                            competenceValue={competence2Value}
                            setCompetenceValue={setCompetence2Value}
                          />
                        )}
                        {applicationData.Competence3 && (
                          <CompetenceRatingItem
                            competenceName={applicationData.Competence3}
                            competenceValue={competence3Value}
                            setCompetenceValue={setCompetence3Value}
                          />
                        )}
                        {applicationData.Competence4 && (
                          <CompetenceRatingItem
                            competenceName={applicationData.Competence4}
                            competenceValue={competence4Value}
                            setCompetenceValue={setCompetence4Value}
                          />
                        )}
                        {applicationData.Competence5 && (
                          <CompetenceRatingItem
                            competenceName={applicationData.Competence5}
                            competenceValue={competence5Value}
                            setCompetenceValue={setCompetence5Value}
                          />
                        )}
                      </Box>
                    )}
                    <Box mb={8}>
                      <Typography variant="h6" fontSize="1.25rem !important" mb="1rem !important">
                        {t('cards.card.advice')}
                      </Typography>
                      <Box mt={3}>
                        <StatusSuggestion
                          changingStatus={changingStatus}
                          status={ratingValue}
                          setStatus={handleStatus}
                          role={'committee_member'}
                          originalStatus={applicationData.StatusCode}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6" fontSize="1.25rem !important" mb=".5rem !important">
                        {t('cards.card.extracomment')}
                      </Typography>
                      <Box>
                        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={1}>
                          <TextField
                            value={comment}
                            onChange={(event) => {
                              setComment(event.target.value);
                            }}
                            sx={(theme) => ({
                              flex: 1,
                              maxWidth: '460px',
                              borderBottom: `1px solid ${theme.vars.palette.grey.A700}`,
                            })}
                            label={t('cards.card.comment.comment')}
                            variant="standard"
                          />
                          <Box sx={{ textAlign: 'right' }}>
                            {buttonDisabledStatus && (
                              <Tooltip arrow placement="top" title={t('cards.card.comment.required')}>
                                <LoadingButton
                                  variant="contained"
                                  disableRipple={true}
                                  disableElevation={true}
                                  disableFocusRipple={true}
                                  sx={{
                                    borderRadius: '2em',
                                    '&:hover': {
                                      cursor: 'inherit',
                                    },
                                  }}
                                >
                                  {t('cards.card.comment.save')}
                                </LoadingButton>
                              </Tooltip>
                            )}
                            {!buttonDisabledStatus && (
                              <LoadingButton
                                disabled={buttonDisabledStatus}
                                onClick={async () => {
                                  handleCommentAndRatings();
                                }}
                                variant="contained"
                                sx={{ borderRadius: '2em' }}
                                loading={postComment}
                              >
                                {t('cards.card.comment.save')}
                              </LoadingButton>
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    {canChangeDecision && (
                      <Box mt={4}>
                        <DetailDialogSectionTitle
                          sx={(theme) => ({ color: `${theme.vars.palette.text.primary} !important` })}
                          variant="h1"
                          className="detail-dialog-section-title"
                        >
                          {`${t('cards.card.decide')}`}
                        </DetailDialogSectionTitle>
                        <Typography variant="h6" fontSize="1.25rem !important" mb="1rem !important">
                          {t('cards.card.decision')}
                        </Typography>
                        <Box mt={3}>
                          <StatusSuggestion
                            changingStatus={changingDecision}
                            status={decisionValue}
                            setStatus={handleDecision}
                            role={role}
                            originalStatus={applicationData.StatusCode}
                          />
                        </Box>
                        <Box>
                          <Stack justifyContent="flex-end" direction="row">
                            <LoadingButton
                              onClick={async () => {
                                handleDecisionSaving();
                              }}
                              variant="contained"
                              sx={{ borderRadius: '2em' }}
                              loading={savingDecision}
                            >
                              {t('cards.card.comment.save')}
                            </LoadingButton>
                          </Stack>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  flex: '1 1 55%',
                }}
              >
                {commentDataIsLoading && (
                  <>
                    <Skeleton variant="rounded" height={750} />
                  </>
                )}
                {!commentDataIsLoading && commentData && (
                  <>
                    <DetailDialogSectionTitle
                      sx={(theme) => ({ color: `${theme.vars.palette.text.primary} !important` })}
                      variant="h1"
                      className="detail-dialog-section-title"
                    >
                      {t('cards.card.colleaguesreviews')}
                    </DetailDialogSectionTitle>
                    <Box>
                      <List
                        sx={{
                          maxHeight: hasCompetences(applicationData)
                            ? canChangeDecision
                              ? '850px'
                              : '600px'
                            : canChangeDecision
                            ? '675px'
                            : '400px',
                          overflowY: 'scroll',
                          paddingRight: '.5rem',
                          '&::-webkit-scrollbar': {
                            width: '0.5rem',
                            backgroundColor: 'transparent',
                            borderRadius: '0.5rem',
                            '&-thumb': {
                              backgroundColor: 'rgba(0,0,0,0.1)',
                              borderRadius: '0.5rem',
                              width: '0.5rem',
                              '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.2)',
                              },
                            },
                          },
                        }}
                      >
                        {comments?.length > 0 &&
                          comments.map((comment) => {
                            const personId = comment.PersonID;
                            const thisMember = members[personId];
                            if (!thisMember) {
                              return null;
                            }
                            return (
                              <CommentListItem
                                comment={comment}
                                key={comment.Date}
                                member={thisMember}
                                applicationData={applicationData}
                              />
                            );
                          })}
                        {comments?.length === 0 && (
                          <Box
                            p={2}
                            borderRadius={2}
                            mb={2}
                            sx={(theme) => ({
                              backgroundColor: theme.vars.palette.grey.A200,
                            })}
                          >
                            <Typography align="center" variant="body1">
                              {t('cards.card.comment.norating')}
                            </Typography>
                          </Box>
                        )}
                      </List>
                    </Box>
                  </>
                )}
                {commentDataIsLoading && (
                  <>
                    <ListItem
                      sx={{
                        paddingLeft: '1.5em',
                        paddingRight: '1.5em',
                      }}
                    >
                      <Card
                        sx={{
                          bgcolor: '#edf4f3',
                          boxShadow: 'unset',
                          borderRadius: '.95rem',
                          flex: 1,
                        }}
                      >
                        <Skeleton variant="rounded" height={160} animation="wave" />
                      </Card>
                    </ListItem>
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CardReviewSection;
