import { Box, ListItem, ListItemText } from '@mui/material';

const CardListItemPersonal = ({ label, answer }) => {
  return (
    <>
      {label && answer && (
        <ListItem disableGutters disablePadding>
          <ListItemText
            sx={{
              marginTop: 0,
              marginBottom: 1,
              '& .MuiTypography-root': {
                display: 'flex',
              },
            }}
          >
            <Box component="span" sx={{ fontSize: '.9rem', flex: '1 1 50%' }}>
              {label}{' '}
            </Box>
            <Box
              component="span"
              sx={{ marginLeft: '.5em', fontSize: '.9rem', fontWeight: 700, flex: '1 1 50%' }}
            >
              {answer}
            </Box>
          </ListItemText>
        </ListItem>
      )}
    </>
  );
};

export default CardListItemPersonal;
