import { Avatar, Button, Divider, IconButton, Stack, Typography, Box, Badge, AppBar } from '@mui/material';
import RecruitmentIconSvg from '../images/RecruitmentIcon.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import { InSiteContext } from '../context/InSiteContext';

const NavLink = ({ text, target, badge, blank }) => {
  const location = useLocation();

  const active = location.pathname === target;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      component={RouterLink}
      to={target ? target + window.location.search : location}
      target={blank ? '_blank' : '_self'}
      sx={{
        marginInline: 2,
        mr: 2,
        position: 'relative',
        height: '100%',
        transition: 'border-bottom 0.1s ease',
        cursor: 'pointer',
        borderBottom: active ? '4px solid' : 'none',
        '&:hover': {
          borderBottom: '4px solid',
          '& .MuiTypography-root': { transition: 'font-weight 0.1s ease', fontWeight: 700 },
        },
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          display: 'block',
          textDecoration: 'none',
          fontWeight: active ? 700 : 400,
          fontSize: '15px',
          pr: badge ? 4 : 0,
        }}
      >
        <Badge
          invisible={!badge}
          badgeContent={badge}
          max={99}
          color="primary"
          overlap="rectangular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={(theme) => ({
            '& .MuiBadge-badge': {
              bgcolor: theme.vars.palette.primary.main,
              color: theme.vars.palette.background.default,
              width: 26,
              height: 26,
              borderRadius: 26 / 2,
              fontWeight: 700,
              right: -20,
              top: 10,
            },
          })}
        >
          {text}
        </Badge>
      </Typography>
    </Stack>
  );
};

function RecruitmentHeader({ numberVacancies }) {
  const { t } = useTranslation('common');
  const insiteUrl = useContext(InSiteContext);
  let createApplicantUrl = `${insiteUrl}/aanmaken-sollicitant-prs/aanmaken-sollicitant-prs`;
  if (insiteUrl.includes('86961.insitetest') || insiteUrl.includes('51268.insiteaccept')) {
    createApplicantUrl = `${insiteUrl}/aanmaken-sollicitant-prs/aanmaken-sollicitant`;
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mb: 8, mt: 5 }}
      width="100%"
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2.25}>
        <Avatar
          alt="Recruitment icon"
          src={RecruitmentIconSvg}
          sx={(theme) => ({
            width: 'auto',
            height: 'auto',
            bgcolor: theme.vars.palette.primary.main,
            padding: '1.225em',
          })}
        />
        <Divider
          orientation="vertical"
          sx={(theme) => ({
            height: '5em',
            borderRightWidth: '1.4px',
            opacity: '0.7',
            borderColor: theme.vars.palette.common.black,
          })}
        />
        <Typography variant="h1" letterSpacing="0.875px" fontSize="25px !important" lineHeight="normal">
          {t('recruitment.title')}
        </Typography>
      </Stack>
      <Box width="1088px">
        <AppBar
          component="nav"
          position="static"
          sx={(theme) => ({
            background: theme.vars.palette.background.default,
            borderRadus: '5px',
            boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
            height: '70px',
            pl: 1,
            pr: 2,
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            height="100%"
          >
            <Stack direction="row" alignContent="center" alignItems="center" height="100%">
              <NavLink text={t('recruitment.options.review')} target={'/'} badge={numberVacancies} />
              <NavLink text={t('recruitment.options.overview')} target={'/candidates/'} />
              <NavLink
                text={t('recruitment.options.dashboard')}
                target={'https://lookerstudio.google.com/reporting/8fc12f1a-6556-4423-908a-d11edab4a79e'}
                blank={true}
              />
            </Stack>

            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Box>
                <Button
                  variant="contained"
                  className="recruitment-btn recruitment-btn-light"
                  startIcon={<AddOutlinedIcon />}
                  href={createApplicantUrl}
                  target="_blank"
                >
                  <Typography fontSize="16px" noWrap>
                    {t('recruitment.options.add')}
                  </Typography>
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  className="recruitment-btn recruitment-btn-dark"
                  startIcon={<AddOutlinedIcon />}
                  href={`${insiteUrl}/aanmaken-vacature-incl-autorisatie-prs/gladior-aanmaken-vacature`}
                  target="_parent"
                >
                  <Typography fontSize="16px" noWrap>
                    {t('recruitment.options.request')}
                  </Typography>
                </Button>
              </Box>
              <Box>
                <IconButton
                  variant="contained"
                  target="_blank"
                  className="recruitment-btn"
                  href={`${insiteUrl}/gladior-ats/ats-managment`}
                  size="large"
                  sx={(theme) => ({
                    ml: '0 !important',
                    bgcolor: theme.palette.grey.A100,
                    px: 2,
                    '&:hover': {
                      bgcolor: theme.vars.palette.common.black,
                      color: theme.vars.palette.background.default,
                    },
                  })}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>
        </AppBar>
      </Box>
    </Stack>
  );
}

export default RecruitmentHeader;
