import { Avatar, Badge, Divider, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import ProgressIcon from '../images/Progress_Icon.svg';

const ProgressHeader = ({ committeeCount }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Box mb={4} mt={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
          <Badge
            badgeContent={committeeCount}
            color="primary"
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={(theme) => ({
              '& .MuiBadge-badge': {
                bgcolor: theme.vars.palette.common.background,
                color: theme.vars.palette.common.onBackground,
                width: 36,
                height: 36,
                borderRadius: 36 / 2,
                fontWeight: 700,
              },
            })}
          >
            <Avatar
              alt="Vacancies to evaluate"
              src={ProgressIcon}
              sx={(theme) => ({
                p: '1em',
                width: 88,
                height: 88,
                boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
                bgcolor: theme.vars.palette.primary.main,
                '& .MuiAvatar-img': {
                  objectFit: 'contain',
                },
              })}
            />
          </Badge>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography
              variant="h1"
              my={4}
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1.5rem',
                  lg: '2rem',
                },
              }}
            >
              {t('vacancies.progress.title')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ProgressHeader;
