import { useTranslation } from 'react-i18next';

export default function useWords(input) {
  const { t } = useTranslation('common');
  switch (input) {
    case 'F':
      return t('female');
    case 'M':
      return t('male');
    case 'X':
      return t('x');
    case 'U':
      return t('unknown');
    default:
      break;
  }
}
