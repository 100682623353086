import { Box, ListItem, ListItemText } from '@mui/material';

const CardListItem = ({ label, answer }) => {
  return (
    <>
      {label && answer && (
        <ListItem disableGutters disablePadding>
          <ListItemText
            sx={{
              marginTop: 0,
              marginBottom: 1,
            }}
          >
            <Box component="span" sx={{ fontWeight: 700, fontSize: '.9rem' }}>
              {label}{' '}
            </Box>
            <Box component="span" sx={{ marginLeft: '.5em', fontSize: '.9rem' }}>
              {answer}
            </Box>
          </ListItemText>
        </ListItem>
      )}
    </>
  );
};

export default CardListItem;
