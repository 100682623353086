import { Box, Grid, LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProgressCard from '../components/ProgressCard';
import ProgressHeader from '../components/ProgressHeader';
import useAfasData from '../hooks/useAfasData';
import BackToOverview from '../components/BackToOverview';

const Progress = () => {
  let params = useParams();
  let vacancyId = params.VacancyID;

  const { data = [], isLoading } = useAfasData(`/vacancies/${vacancyId}/committeemembers`, {});

  return (
    <>
      <ProgressHeader committeeCount={data.length} />
      <Box my={4}>
        <BackToOverview />
      </Box>
      {isLoading && <LinearProgress></LinearProgress>}
      {!isLoading && data && (
        <Grid container spacing={4} mb={4}>
          {data.map((member) => (
            <ProgressCard key={member.PersonID} member={member} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default Progress;
