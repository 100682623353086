import Typography from '@mui/material/Typography';
import { Avatar, Box, ToggleButton } from '@mui/material';
import { forwardRef } from 'react';

const OverviewHeaderButton = forwardRef(
  (
    { parseValue, color, icon, number, text, selected, onChange, width = 40, height = 40, classAddOn = '' },
    ref
  ) => (
    <Box
      className="cards-header-button"
      sx={{
        textAlign: 'center',
        maxWidth: '120px',
      }}
    >
      <ToggleButton
        ref={ref}
        value={parseValue}
        variant="text"
        selected={selected === parseValue}
        className={
          selected === parseValue
            ? `Mui-Selected ev_applications-grid-status-filter-${classAddOn}`
            : `ev_applications-grid-status-filter-${classAddOn}`
        }
        onChange={onChange}
        sx={{
          p: 2,
          display: 'block',
          width: 90,
          height: 90,
          bgcolor: '#FFF',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: 'unset !important',
          fontWeight: 700,
          textAlign: 'center',
          borderRadius: '100%',
          boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
          '&:hover': {
            bgcolor: color,
            border: 'unset !important',
          },
          '&:hover .MuiTypography-h4': {
            color: '#FFF !important',
          },
          '&:hover .MuiAvatar-img': {
            filter: 'brightness(100)',
          },
          '&.Mui-Selected': {
            bgcolor: color,
          },
          '&.Mui-Selected:hover': {
            bgcolor: `${color} !important`,
          },
          '&.Mui-Selected .MuiTypography-h4': {
            color: '#FFF !important',
          },
          '&.Mui-Selected .MuiAvatar-img': {
            filter: 'brightness(100)',
          },
        }}
      >
        <Avatar
          alt="Icon"
          src={icon}
          sx={{
            width: Number(width),
            height: Number(height),
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '0',
            '& .MuiAvatar-img': {
              objectFit: 'contain',
              transition: 'all ease-in-out 250ms',
            },
          }}
        />
        <Typography
          variant="h4"
          color={color}
          mt={1}
          sx={{
            fontSize: '1.15rem',
            transition: 'all ease-in-out 250ms',
            color: `${color} !important`,
          }}
        >
          {number}
        </Typography>
      </ToggleButton>
      <Box mt={1}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '.85rem !important',
            minWidth: '120px',
            whiteSpace: 'pre-wrap',
            fontWeight: `500 !important`,
          }}
          color="initial"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
);

export default OverviewHeaderButton;
