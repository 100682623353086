import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputBase,
  Stack,
  SvgIcon,
  FormControl,
  NativeSelect,
  LinearProgress,
  Grid,
  Typography,
  Avatar,
  Tooltip,
  Badge,
} from '@mui/material';
import { Box } from '@mui/system';
import FuzzySearch from 'fuzzy-search';
import { forwardRef, useEffect, useState } from 'react';
import useAfasData from '../hooks/useAfasData';
import SwiperCard from '../components/SwiperCard';
import { useTranslation } from 'react-i18next';
import TotalAppliedIcon from '../images/TotalAppliedCandidates.svg';
import CheckIcon from '../images/Check_Icon.svg';
import OnHoldIcon from '../images/OnHoldIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import ReviewedIcon from '../images/ReviewedIcon.svg';
import OverviewHeaderButton from '../components/OverviewHeaderButton';
import _orderBy from 'lodash/orderBy';
import BackToOverview from '../components/BackToOverview';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import styled from '@emotion/styled';
import OverviewHeaderTitle from '../components/OverviewHeaderTitle';

export const getStatusColor = (statusCode) => {
  /**
   *  Rejected = '005',
   *  SelectionCommittee = '002',
   *  Invited = '003',
   *  OnHold = '004',
   *  Completed = '007',
   *  BeingProcessed = '002',
   *  RejectedByChairPerson = '008',
   *  Employed = '006',
   *  OnHoldEmailSent = '009',
   */
  switch (statusCode) {
    case '005':
      return '#E81515';
    case '008':
      return '#E81515';
    case '002':
      return '#afafaf';
    case '003':
      return '#17B733';
    case '004':
      return '#0060AA';
    case '009':
      return '#0060AA';
    default:
      return '#888888';
  }
};

const getStatusIcon = (statusCode) => {
  switch (statusCode) {
    case '005':
      return RejectedIcon;
    case '008':
      return RejectedIcon;
    case '002':
      return ReviewedIcon;
    case '003':
      return CheckIcon;
    case '004':
      return OnHoldIcon;
    case '009':
      return OnHoldIcon;
    default:
      return ReviewedIcon;
  }
};

function CardSwipeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 640 512">
      <path fill={props?.fill ? props.fill : '#ADAFBB'} d="M 220.7 7.468 C 247.3 -7.906 281.4 1.218 296.8 27.85 L 463.8 317.1 C 479.1 343.8 470 377.8 443.4 393.2 L 250.5 504.5 C 223.9 519.9 189.9 510.8 174.5 484.2 L 7.468 194.9 C -7.906 168.2 1.218 134.2 27.85 118.8 L 220.7 7.468 Z M 324.1 499 L 459.4 420.9 C 501.3 396.7 515.7 343.1 491.5 301.1 L 354.7 64.25 C 356.5 64.08 358.2 63.1 360 63.1 L 584 63.1 C 614.9 63.1 640 89.07 640 119.1 L 640 456 C 640 486.9 614.9 512 584 512 L 360 512 C 346.4 512 333.8 507.1 324.1 498.1 L 324.1 499 Z" />
    </SvgIcon>
  );
}

function CardOverviewIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 448 512">
      <path fill={props?.fill ? props.fill : '#ADAFBB'} d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C128 46.33 113.7 32 96 32zM256 32H192C174.3 32 160 46.33 160 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C288 46.33 273.7 32 256 32zM416 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C448 46.33 433.7 32 416 32zM96 352H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C128 366.3 113.7 352 96 352zM256 352H192c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C288 366.3 273.7 352 256 352zM416 352h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C448 366.3 433.7 352 416 352zM96 192H32C14.33 192 0 206.3 0 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C128 206.3 113.7 192 96 192zM256 192H192C174.3 192 160 206.3 160 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C288 206.3 273.7 192 256 192zM416 192h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C448 206.3 433.7 192 416 192z" />
    </SvgIcon>
  );
}

const CardStatusAvatar = styled(Avatar)({
  position: 'absolute',
  right: '1em',
  top: 0,
  transform: 'translateY(-50%)',
  width: '48px',
  height: '48px',
  boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
  '& .MuiAvatar-img': {
    filter: 'brightness(100)',
    margin: 'auto',
    objectFit: 'contain',
  },
});

const TooltipInner = forwardRef(function TooltipInner(props, ref) {
  return (
    <span {...props} ref={ref}>
      {props?.children}
    </span>
  );
});

function BadgeEmailSent({ children, application }) {
  const { t } = useTranslation('common');
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        application.StatusCode === '009' ||
          application.StatusCode === '005' ||
          application.StatusCode === '007' ||
          application.StatusCode === '006' ? (
          <Tooltip arrow title={t('cards.card.contacted')}>
            <TooltipInner>
              <Box
                sx={(theme) => ({
                  backgroundColor: getStatusColor(application.StatusCode),
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.125rem',
                  transform: 'translate(90%, 40%)',
                  outlineOffset: '0px',
                  outlineWidth: '3px',
                  outlineStyle: 'solid',
                })}
              >
                <MarkEmailReadOutlinedIcon fontSize=".9rem" htmlColor="#FFF" />
              </Box>
            </TooltipInner>
          </Tooltip>
        ) : (
          ''
        )
      }
    >
      {children}
    </Badge>
  );
}

function VacancyOverview() {
  const { t } = useTranslation('common');
  const params = useParams();
  let { state, search } = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [sortObj, setSortObj] = useState({ key: 'ApplicationID', order: 'desc' });
  const [debouncedSortObj, setDebouncedSortObj] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 1000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [searchValue]);

  useEffect(() => {
    const debounceSortId = setTimeout(() => {
      setDebouncedSortObj(sortObj);
    }, 100);
    return () => {
      clearTimeout(debounceSortId);
    };
  }, [sortObj]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'searchOverview',
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (JSON.stringify(debouncedSortObj) !== '{"key":"ApplicationID","order":"desc"}') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'sortOverview',
        key: JSON.stringify(debouncedSortObj?.key),
        order: JSON.stringify(debouncedSortObj?.order),
      });
    }
  }, [debouncedSortObj]);

  const vacancyId = params.VacancyID;
  const totalCandidates = state?.candidates;
  let initialState = state?.statusCode ?? 'all';

  const {
    data: applicationData = [],
    isLoading: applicationIsLoading,
    mutate: applicationMutator,
  } = useAfasData(`/vacancy/${vacancyId}/applications/list`, {});

  const {
    data: vacancyData = {},
    isLoading: vacancyIsLoading,
    mutate: infoMutator,
  } = useAfasData(`/vacancies/${vacancyId}/info`, {});
  /**
   * TotalCandidates: 32
   * TotalInvited: 9
   * TotalOnHold: 11
   * TotalRejected: 11
   * TotalReviewOpen: 1
   * VacancyID: 762
   * VacancyTitle: "Assistant Professor: Artificial Intelligence in Biomechanics and Robotics"
   * Competence1: mainVacancy.Competence1 || '',
   * Competence2: mainVacancy.Competence2 || '',
   * Competence3: mainVacancy.Competence3 || '',
   * Competence4: mainVacancy.Competence4 || '',
   * Competence5: mainVacancy.Competence5 || '',
   * Role: vacancyRole,
   */

  const competences = [];
  if (vacancyData) {
    if (vacancyData.Competence1 !== '') {
      competences.push(vacancyData.Competence1);
    }
    if (vacancyData.Competence2 !== '') {
      competences.push(vacancyData.Competence2);
    }
    if (vacancyData.Competence3 !== '') {
      competences.push(vacancyData.Competence3);
    }
    if (vacancyData.Competence4 !== '') {
      competences.push(vacancyData.Competence4);
    }
    if (vacancyData.Competence5 !== '') {
      competences.push(vacancyData.Competence5);
    }
  }

  const [statusFilter, setStatusFilter] = useState(initialState ?? 'all');

  const handleStatusFilter = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
    }
  };

  const handleChange = (event) => {
    switch (event.target.value) {
      case 'name-asc':
        setSortObj({
          key: 'ApplicantName',
          order: 'asc',
        });
        break;
      case 'name-desc':
        setSortObj({
          key: 'ApplicantName',
          order: 'desc',
        });
        break;
      case 'competence1':
        setSortObj({
          key: 'Competence1Average',
          order: 'desc',
        });
        break;
      case 'competence2':
        setSortObj({
          key: 'Competence2Average',
          order: 'desc',
        });
        break;
      case 'competence3':
        setSortObj({
          key: 'Competence3Average',
          order: 'desc',
        });
        break;
      case 'competence4':
        setSortObj({
          key: 'Competence4Average',
          order: 'desc',
        });
        break;
      case 'competence5':
        setSortObj({
          key: 'Competence5Average',
          order: 'desc',
        });
        break;
      case 'oldest':
        setSortObj({
          key: 'ApplicationID',
          order: 'asc',
        });
        break;
      case 'latest':
        setSortObj({
          key: 'ApplicationID',
          order: 'desc',
        });
        break;
      case 'rating-asc':
        setSortObj({
          key: ['SuggestInvite', 'SuggestOnHold', 'SuggestReject'],
          order: ['asc', 'asc', 'asc'],
        });
        break;
      case 'rating-desc': // Hoogste naar laagste beoordeling
        setSortObj({
          key: ['SuggestInvite', 'SuggestOnHold', 'SuggestReject'],
          order: ['desc', 'desc', 'desc'],
        });
        break;
      default:
        break;
    }
  };

  const applicationSearcher = new FuzzySearch(applicationData, ['ApplicantName'], {
    caseSensitive: false,
    sort: false,
  });

  let result = applicationSearcher.search(searchValue);

  result = result.map((application) => {
    if (application.Rating === '-' || application.Rating === null) application.Rating = 0;
    if (application.Competence1Average === null) application.Competence1Average = -1;
    if (application.Competence2Average === null) application.Competence2Average = -1;
    if (application.Competence3Average === null) application.Competence3Average = -1;
    if (application.Competence4Average === null) application.Competence4Average = -1;
    if (application.Competence5Average === null) application.Competence5Average = -1;
    return application;
  });

  if (statusFilter && statusFilter !== 'all') {
    const status = statusFilter.split(',');
    result =
      statusFilter === '002'
        ? result.filter((application) => application.ReviewedByUser === false)
        : result.filter((application) => status.includes(application.StatusCode));
  }

  result = _orderBy(
    result,
    Array.isArray(sortObj.key) ? sortObj.key : [sortObj.key],
    Array.isArray(sortObj.order) ? sortObj.order : [sortObj.order]
  );

  return (
    <>
      <Box mb={4} mt={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="start"
          spacing={4}
        >
          <OverviewHeaderTitle vacancyData={vacancyData} vacancyIsLoading={vacancyIsLoading} />
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: 0,
            }}
          >
            <OverviewHeaderButton
              parseValue="all"
              color="#4e1370"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.applied')}
              number={vacancyData.TotalCandidates || totalCandidates}
              icon={TotalAppliedIcon}
              width="32"
              height="32"
              classAddOn={'total'}
            />
            <OverviewHeaderButton
              parseValue="003"
              color="#17B733"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.invited')}
              number={vacancyData.TotalInvited || '0'}
              icon={CheckIcon}
              width="32"
              height="32"
              classAddOn={'invited'}
            />
            <OverviewHeaderButton
              parseValue="004,009"
              color="#0060AA"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.onhold')}
              number={vacancyData.TotalOnHold || '0'}
              icon={OnHoldIcon}
              width="32"
              height="32"
              classAddOn={'onhold'}
            />
            <OverviewHeaderButton
              parseValue="005,008"
              color="#E81515"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.rejected')}
              number={vacancyData.TotalRejected || '0'}
              icon={RejectedIcon}
              width="32"
              height="32"
              classAddOn={'rejected'}
            />
            <OverviewHeaderButton
              parseValue='002'
              color="#afafaf"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.reviewed')}
              number={vacancyData.TotalReviewOpen || '0'}
              icon={ReviewedIcon}
              width="32"
              height="32"
              classAddOn={'reviewed'}
            />
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={8}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <BackToOverview />
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <FormControl sx={{ minWidth: '200px', maxWidth: '250px' }}>
              <NativeSelect
                onChange={handleChange}
                defaultValue={`latest`}
                sx={{
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  height: '44px',
                  backgroundColor: '#FFF',
                }}
              >
                <option value={`latest`}>{t('vacancies.overview.sort.newtoold')}</option>
                <option value={`oldest`}>{t('vacancies.overview.sort.oldtonew')}</option>
                <option value={`name-asc`}>{t('vacancies.overview.sort.alphaasc')}</option>
                <option value={`name-desc`}>{t('vacancies.overview.sort.alphadesc')}</option>
                <option value={`rating-desc`}>{t('vacancies.overview.sort.hightolow')}</option>
                <option value={`rating-asc`}>{t('vacancies.overview.sort.lowtohigh')}</option>
                {vacancyData.Competence1 && (
                  <option value={`competence1`}>
                    {vacancyData.Competence1} {t('vacancies.overview.sort.desc')}
                  </option>
                )}
                {vacancyData.Competence2 && (
                  <option value={`competence2`}>
                    {vacancyData.Competence2} {t('vacancies.overview.sort.desc')}
                  </option>
                )}
                {vacancyData.Competence3 && (
                  <option value={`competence3`}>
                    {vacancyData.Competence3} {t('vacancies.overview.sort.desc')}
                  </option>
                )}
                {vacancyData.Competence4 && (
                  <option value={`competence4`}>
                    {vacancyData.Competence4} {t('vacancies.overview.sort.desc')}
                  </option>
                )}
                {vacancyData.Competence5 && (
                  <option value={`competence5`}>
                    {vacancyData.Competence5} {t('vacancies.overview.sort.desc')}
                  </option>
                )}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'inline-block',
              backgroundColor: '#FFF',
            }}
          >
            <InputBase
              sx={{
                flex: 1,
                px: 2,
              }}
              placeholder={t('vacancies.overview.search')}
              inputProps={{ 'aria-label': 'Search by name' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
          <IconButton
            className="ev_view-vacancy-applications-swipe"
            onClick={() => {
              navigate({
                pathname: `/vacancies/${vacancyId}/applications/cards/`,
                search: search,
              });
            }}
            sx={{
              borderBottom: '3px solid transparent',
              borderRadius: '0px',
            }}
          >
            <CardSwipeIcon width="30" height="34" />
          </IconButton>
          <IconButton
            sx={{
              borderBottom: '3px solid',
              borderRadius: '0px',
            }}
          >
            <CardOverviewIcon width="30" height="34" />
          </IconButton>
        </Stack>
      </Stack>
      <Box pb={5} mt={5}>
        {applicationIsLoading && <LinearProgress mt={2} />}
        {result?.length === 0 && !applicationIsLoading && (
          <Typography variant="h4" color="initial" align="center">
            {t('cards.card.noresults')}
          </Typography>
        )}
        {result && vacancyData && !applicationIsLoading && (
          <Grid
            container
            spacing={6}
            alignItems="stretch"
            justifyContent="flex-start"
            direction="row"
            className="overview-grid"
          >
            {result.map((application) => {
              return (
                <Grid item xs={12} md={4} lg={3} mb={3} key={application.ApplicationID}>
                  <SwiperCard
                    application={application}
                    role={vacancyData.Role}
                    view="overview"
                    competences={competences}
                    applicationMutator={applicationMutator}
                    infoMutator={infoMutator}
                  >
                    {(application.ReviewedByUser && application.StatusCode === '002') ? (<></>) : (
                      <>
                        {(application.StatusCode === '007') && (
                          <CardStatusAvatar
                            sx={{
                              bgcolor: getStatusColor(application.StatusCode),
                            }}
                          >
                            <EventAvailableIcon />
                          </CardStatusAvatar>
                        )}
                        {(application.StatusCode !== '007') && (
                          <BadgeEmailSent application={application}>
                            <CardStatusAvatar
                              sx={{
                                bgcolor: getStatusColor(application.StatusCode),
                                '& .MuiAvatar-img': {
                                  width: '32px',
                                  lineHeightStep: '32px',
                                },
                              }}
                              alt="Icon"
                              src={getStatusIcon(application.StatusCode)}
                            />
                          </BadgeEmailSent>
                        )}
                      </>
                    )}
                  </SwiperCard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Outlet context={[result]} />
    </>
  );
}

export default VacancyOverview;
